<template>
    <div>
        <v-row>
            <v-col cols="12" v-if="loading">
                <v-skeleton-loader dense type="table-row-divider@2" class="mx-auto"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" v-if="!loading">
                <div class="font-weight-bold font-24">Managerment Fee Tracking</div>
            </v-col>
            <v-col cols="2" v-if="!loading">
                <v-data-table dense disable-sort hide-default-header hide-default-footer>
                    <template v-slot:body>
                        <tbody>
                            <tr v-if="dayKeys.length == 0">
                                <td colspan="2" class="text-center">No Data Available</td>
                            </tr>
                            <tr v-if="dayKeys.length > 0">
                                <td class="px-0 py-1 pb-2">
                                    <span class="font-16">Pool</span>
                                </td>
                            </tr>
                            <tr v-if="dayKeys.length > 0" v-for="(stats, index) in dayData[dayKey]" :key="index">
                                <td class="px-0 font-32">{{ stats.poolName }}</td>
                                <!-- <td class="text-right px-2">{{ '$' + common.formatNum(stats.value.toFixed(2)) }}</td> -->
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="2" v-if="!loading">
                <v-data-table dense disable-sort hide-default-header hide-default-footer>
                    <template v-slot:body>
                        <tbody>
                            <tr v-if="dayKeys.length == 0">
                                <td colspan="2" class="text-center">No Data Available</td>
                            </tr>
                            <tr v-if="dayKeys.length > 0">
                                <!-- <td></td> -->
                                <td class="px-0 text--right">
                                    <v-select v-model="dayKey" :items="dayKeys" class="pr-0 mx-0 float-right" hide-details dense solo flat></v-select>
                                </td>
                            </tr>
                            <tr v-if="dayKeys.length > 0" v-for="(stats, index) in dayData[dayKey]" :key="index">
                                <!-- <td class="px-2">{{ stats.poolName }}</td> -->
                                <td class="text-right px-0">{{ stats.value == 0 ? '--' : ('$' + common.formatNum(stats.value.toFixed(2))) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="2" v-if="!loading">
                <v-data-table dense disable-sort hide-default-header hide-default-footer>
                    <template v-slot:body>
                        <tbody>
                            <tr v-if="monthKeys.length == 0">
                                <td colspan="2" class="text-center">No Data Available</td>
                            </tr>
                            <tr v-if="monthKeys.length > 0">
                                <!-- <td></td> -->
                                <td class="px-0 text--right">
                                    <v-select v-model="monthKey" :items="monthKeys" class="pr-0 mx-0 float-right" hide-details dense solo flat></v-select>
                                </td>
                            </tr>
                            <tr v-if="monthKeys.length > 0" v-for="(stats, index) in monthData[monthKey]" :key="index">
                                <!-- <td class="px-2">{{ stats.poolName }}</td> -->
                                <td class="text-right px-0">{{ stats.value == 0 ? '--' : ('$' + common.formatNum(stats.value.toFixed(2))) }}</td>
                            </tr>
                            <!-- <tr v-if="monthKeys.length > 0" v-for="(stats, index) in monthList" :key="index">
                                <td class="px-2">{{ stats.name }}</td>
                                <td class="text-right px-2">{{ '$' + common.formatNum(stats.subValue.toFixed(2)) }}</td>
                                <td class="text-right px-2">{{ '$' + common.formatNum(stats.value.toFixed(2)) }}</td>
                            </tr> -->
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="2" v-if="!loading">
                <v-data-table dense disable-sort hide-default-header hide-default-footer>
                    <template v-slot:body>
                        <tbody>
                            <tr v-if="quarterKeys.length == 0">
                                <td colspan="2" class="text-center">No Data Available</td>
                            </tr>
                            <tr v-if="quarterKeys.length > 0">
                                <!-- <td></td> -->
                                <td class="px-0 text--right">
                                    <v-select v-model="quarterKey" :items="quarterKeys" class="pr-0 mx-0 float-right" hide-details dense solo flat></v-select>
                                </td>
                            </tr>
                            <tr v-if="quarterKeys.length > 0" v-for="(stats, index) in quarterData[quarterKey]" :key="index">
                                <!-- <td class="px-2">{{ stats.poolName }}</td> -->
                                <td class="text-right px-0">{{ stats.value == 0 ? '--' : ('$' + common.formatNum(stats.value.toFixed(2))) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="2" v-if="!loading">
                <v-data-table dense disable-sort hide-default-header hide-default-footer>
                    <template v-slot:body>
                        <tbody>
                            <tr v-if="yearKeys.length == 0">
                                <td colspan="2" class="text-center">No Data Available</td>
                            </tr>
                            <tr v-if="yearKeys.length > 0">
                                <!-- <td></td> -->
                                <td class="px-0 text--right">
                                    <v-select v-model="yearKey" :items="yearKeys" class="pr-0 mx-0 text--right" hide-details dense solo flat></v-select>
                                </td>
                            </tr>
                            <tr v-if="yearKeys.length > 0" v-for="(stats, index) in yearData[yearKey]" :key="index">
                                <!-- <td class="px-2">{{ stats.poolName }}</td> -->
                                <td class="text-right px-0">{{ stats.value == 0 ? '--' : ('$' + common.formatNum(stats.value.toFixed(2))) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>

        </v-row>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '@/config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                loading: false,
                dayData: {},
                monthData: {},
                quarterData: {},
                yearData: {},
                dayKeys: [],
                monthKeys: [],
                quarterKeys: [],
                yearKeys: [],
                dayKey: null,
                monthKey: null,
                quarterKey: null,
                yearKey: null
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
            this.getStats();
        },
        created() {
           
        },
        methods: {
            // 获取数据
            getStats() {
                // 0xca38034a69ebedd28ce62368890e7cf187309a0f
                this.loading = true;
                let api = config.baseUrl + '/pool/manager/public/management/fees/stats';
                this.$http.get(api).then(response => {
                    this.loading = false;
                    let data = response.data;
                    if (data.code == 200) {
                       this.dayData = data.result.day;
                       this.monthData = data.result.month;
                       this.quarterData = data.result.quarter;
                       this.yearData = data.result.year;
                       this.dayKeys = data.result.dayKeys;
                       this.monthKeys = data.result.monthKeys;
                       this.quarterKeys = data.result.quarterKeys;
                       this.yearKeys = data.result.yearKeys;
                       this.dayKey = this.dayKeys[0];
                       this.monthKey = this.monthKeys[0];
                       this.quarterKey = this.quarterKeys[0];
                       this.yearKey = this.yearKeys[0];
                    }
               })
            },
        }
    }
</script>

<style>

</style>