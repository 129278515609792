<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12" class="pb-0">
                    <v-alert :dismissible="false" class="green white--text" dark border="left" icon="fas fa-donate">
                        <v-row no-gutters class="align-center mt-n1 mb-n1">
                            <v-col>
                                <span>Pools Fee Stats</span>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
                <v-col cols="12">
                    <pool-performance-fee-tracking></pool-performance-fee-tracking>
                </v-col>
                <v-col cols="12">
                    <pool-management-fee-tracking></pool-management-fee-tracking>
                </v-col>
                <!-- <v-col cols="2" v-if="!loading">
                    <div class="ml-2 font-weight-bold">Monthly Subtotal</div>
                    <div>
                        <v-data-table dense :headers="headers" :items="monthList" disable-sort hide-default-footer v-if="!loading" class="space-nowrap">
                            <template v-slot:body>
                                <tbody>
                                    <tr v-if="monthList.length == 0">
                                        <td colspan="9" class="text-center">No Data Available</td>
                                    </tr>
                                    <tr v-if="monthList.length > 0" v-for="(stats, index) in monthList" :key="index">
                                        <td class="px-2">{{ stats.name }}</td>
                                        <td class="text-right px-2">{{ '$' + common.formatNum(stats.subValue.toFixed(2)) }}</td>
                                        <td class="text-right px-2">{{ '$' + common.formatNum(stats.value.toFixed(2)) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>  
                    </div>
                </v-col> -->
                <!-- <v-col cols="2" v-if="!loading">
                    <div class="ml-2 font-weight-bold">Quarterly Subtotal</div>
                    <div>
                        <v-data-table dense :headers="headers" :items="quarterList" disable-sort hide-default-footer v-if="!loading" class="space-nowrap">
                            <template v-slot:body>
                                <tbody>
                                    <tr v-if="quarterList.length == 0">
                                        <td colspan="9" class="text-center">No Data Available</td>
                                    </tr>
                                    <tr v-if="quarterList.length > 0" v-for="(stats, index) in quarterList" :key="index">
                                        <td class="px-2">{{ stats.name }}</td>
                                        <td class="text-right px-2">{{ '$' + common.formatNum(stats.subValue.toFixed(2)) }}</td>
                                        <td class="text-right px-2">{{ '$' + common.formatNum(stats.value.toFixed(2)) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </div>
                </v-col> -->
                <!-- <v-col cols="2" v-if="!loading">
                    <div class="ml-2 font-weight-bold">Yearly Subtotal</div>
                    <div>
                        <v-data-table dense :headers="headers" :items="yearList" disable-sort hide-default-footer v-if="!loading" class="space-nowrap">
                            <template v-slot:body>
                                <tbody>
                                    <tr v-if="yearList.length == 0">
                                        <td colspan="9" class="text-center">No Data Available</td>
                                    </tr>
                                    <tr v-if="yearList.length > 0" v-for="(stats, index) in yearList" :key="index">
                                        <td class="px-2">{{ stats.name }}</td>
                                        <td class="text-right px-2">{{ '$' + common.formatNum(stats.subValue.toFixed(2)) }}</td>
                                        <td class="text-right px-2">{{ '$' + common.formatNum(stats.value.toFixed(2)) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </div>
                </v-col> -->
                <!-- <v-col cols="2" v-if="!loading">
                    <div class="ml-2 font-weight-bold">Total</div>
                    <div>
                        <v-data-table dense :headers="headers" :items="totalList" disable-sort hide-default-footer v-if="!loading" class="space-nowrap">
                            <template v-slot:body>
                                <tbody>
                                    <tr v-if="totalList.length == 0">
                                        <td colspan="9" class="text-center">No Data Available</td>
                                    </tr>
                                    <tr v-if="totalList.length > 0" v-for="(stats, index) in totalList" :key="index">
                                        <td class="px-2">{{ stats.name }}</td>
                                        <td class="text-right px-2">{{ '$' + common.formatNum(stats.subValue.toFixed(2)) }}</td>
                                        <td class="text-right px-2">{{ '$' + common.formatNum(stats.value.toFixed(2)) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </div>
                </v-col> -->

            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '@/config';
    import { mapGetters } from "vuex";
    import PoolPerformanceFeeTracking from "./PoolPerformanceFeeTracking";
    import PoolManagementFeeTracking from "./PoolManagementFeeTracking";
    export default {
        data() {
            return {
                headers: [
                    { text: 'Date', class: 'px-2' },
                    { text: 'Sub', align: 'end', class: 'px-2' },
                    { text: 'Total', align: 'end', class: 'px-2' },
                ],
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            
        },
        components: {
            PoolPerformanceFeeTracking, PoolManagementFeeTracking
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
        },
        created() {
           
        },
        methods: {
            
        }
    }
</script>

<style>

</style>